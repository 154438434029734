<template>
  <div class="sendMail_modal appHeader">
    <div class="Child_Of_sendMail_modal w-9/12" style="height: 85%">
      <div class="p-4 py-2 flex justify-between items-center border-b">
        <div class="fontWeight-600 text-xs uppercase flex items-center w-full">
          <div>Send Mail To :</div>
          <div class="relative w-6/12">
            <button
              class="captr_N_SendMail absolute"
              style="right: 0px; top: 6px; z-index: 9"
              @click="editToAddress"
            >
              <i class="fas fa-pencil-alt"></i>
            </button>
            <input
            v-if="tomail_disabled"
              ref="toMailInput"
              class="addBg applyFont fontWeight-600 bg-transparent border-0 text-xs px-2 m-0 ml-2 w-full"
              type="email"
              placeholder="To Mail"
              id="toMail"
              v-model="toMailPlaceHolder"
              :disabled="tomail_disabled"
              style="
                height: 25px;
                margin-bottom: 2px;
                border-radius: 3px;
                padding-right: 25px;
              "
              required
              :class="tomail_disabled ? 'bg-none' : ''"
              @blur="() => (tomail_disabled = true)"
            />
            <input
               v-if="!tomail_disabled"
              ref="toMailInput"
              class="addBg applyFont fontWeight-600 bg-transparent border-0 text-xs px-2 m-0 ml-2 w-full"
              type="email"
              placeholder="To Mail"
              id="toMail"
              v-model="toManualEnteredMail"
              :disabled="tomail_disabled"
              style="
                height: 25px;
                margin-bottom: 2px;
                border-radius: 3px;
                padding-right: 25px;
              "
              required
              :class="tomail_disabled ? 'bg-none' : ''"
              @blur="() => (tomail_disabled = true)"
            />
          </div>
        </div>
        <button
          @click="closePoup"
          class="text-sm font-normal uppercase text-red_"
          style="color: #cf634b"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div
        class="w-full flex px-5 py-3 overflow-auto"
        style="gap: 30px; height: calc(100% - 48px)"
      >
        <div style="width: 40%" class="emailSubmit_Form">
          <form @submit.prevent="sendEmail" class="flex flex-col">
            <label for="from" class="text-xs fontWeight-600 mb-1">From :</label>
            <div class="flex mb-3">
              <input
                class="w-full"
                type="email"
                placeholder="From Mail"
                id="from"
                v-model="from"
                required
                disabled
                style="filter: opacity(0.6); font-weight: 600"
              />
              <button
                type="button"
                class="show_Cc_Btn text-xs h-full font-semibold ml-2 mt-2 px-1 py-0.5 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150"
                @click="showCC_Ip_Fun"
              >
                Cc
              </button>
            </div>
            <label v-if="showCC_Ip" for="cc" class="text-xs fontWeight-600 mb-1"
              >Cc :</label
            >
            <input
              v-if="showCC_Ip"
              class="mb-3"
              type="text"
              placeholder="Enter Multiple Email Id's Separated By Comma (,)"
              id="cc"
              v-model="cc"
            />
            <label for="subject" class="text-xs fontWeight-600 mb-1"
              >Subject :</label
            >
            <input
              class="mb-3"
              type="text"
              id="subject"
              placeholder="Subject"
              v-model="subject"
              required
            />
            <label for="body" class="text-xs fontWeight-600 mb-1"
              >Notes :</label
            >
            <textarea
              class="mb-3 px-2 py-1"
              id="body"
              v-model="body"
              rows="10"
              required
            ></textarea>
            <div>
              <button
                class="sendMailbtn bgClr text-white text-xs font-semibold uppercase px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150"
                type="submit"
                :disabled="mail_Sending"
              >
                <span v-if="!mail_Sending">Send</span>
                <span v-if="mail_Sending">Sending...</span>
              </button>
            </div>
          </form>
        </div>
        <div style="width: 60%">
          <canvas id="image_to_show" class="hidden"></canvas>
          <p class="text-xs fontWeight-600 mb-1">Attachment :</p>
          <img
            :src="capturedImage"
            v-if="capturedImage && fileType?.includes('image')"
            style="max-height: 60%"
          />
          <img :src="capturImage" v-if="capturImage && filesType?.includes('image')"
          style="max-height: 60%"/>
          <iframe
            :src="capturedImage"
            v-if="capturedImage && fileType?.includes('application/pdf')"
            style="height: 60%; width: 100%"
          ></iframe>
          <div class="mt-3 flex items-center relative" style="gap: 10px">
            <div
              class="attachmentInfoDiv absolute p-2 text-xs"
              v-if="showAttachmentInfo"
            >
              <div>
                <p>
                  This form supports single file upload, compress/zip to upload
                  multiple files.
                </p>
              </div>
            </div>
            <button
              class="ml-1"
              :style="
                showAttachmentInfo ? { color: 'var(--activeTextColor)' } : ''
              "
              @click="() => (showAttachmentInfo = !showAttachmentInfo)"
              @blur="() => (showAttachmentInfo = false)"
            >
              <i class="fas fa-info-circle"></i>
            </button>
            <input
              class="text-xs"
              ref="Upld_Attchmnt"
              type="file"
              @change="Upload_Attachment"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { blob } from "d3";
import support from "../../api/dictionary/support";
import URL from "../../config";
import axios from "axios";
export default {
  name: "SendMailPoup",
  props: {
    closeModelhandler: Function,
    captchureClickhandler: Function,
    capturImage:Blob,
    filesType:String,
    attachments:Blob
  },
  data() {
    return {
      tomail_disabled: true,
      toMail: "support@apollodart.com",
      attachment: null,
      cc: "",
      login_user_as_cc: "",
      subject: "Issue-",
      body: "",
      mail_Sending: false,
      capturedImage: null,
      fileType: null,
      showAttachmentInfo: false,
      showCC_Ip: false,
      toMailPlaceHolder:"support",
      toManualEnteredMail:""
    };
  },
  methods: {
    editToAddress() {
      this.tomail_disabled = false;
      setTimeout(() => {
        this.$refs.toMailInput.focus();
      }, 0);
    },
    async sendEmail() {
      let ccArray = null;
      if (!this.attachment) {
        this.attachment = null;
      }
      if (!this.cc && !this.login_user_as_cc) {
        ccArray = null;
      } else {
        ccArray = this.cc.replaceAll(" ", "").split(",");
        if (ccArray != "") {
          ccArray = [this.login_user_as_cc, ...ccArray];
        } else {
          ccArray = [this.login_user_as_cc];
        }
        ccArray = [...new Set(ccArray)];
      }
      if(this.toManualEnteredMail!=""){
        this.toMail=this.toMail+","+this.toManualEnteredMail;
      }
      let toMailAddresses = this.toMail.replaceAll(" ", "").split(",");
      toMailAddresses = [...new Set(toMailAddresses)];
      //filtering empty values in array code starts
      toMailAddresses = await toMailAddresses.filter(
        (mail) => mail.replace(/\s/g, "") !== ""
      );
      ccArray = await ccArray.filter((mail) => mail.replace(/\s/g, "") !== "");
      //filtering empty values in array code ends
      if (toMailAddresses == "") {
        this.$toast.clear();
        this.$toast.error(`Please Enter The 'To Mail' Address`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
        this.editToAddress();
        return;
      }
      let formData = new FormData();
      // formData.append("from",this.from)
      formData.append("recipients", toMailAddresses);
      formData.append("cc", ccArray);
      formData.append("messageBody", this.body);
      formData.append("subject", this.subject);
      formData.append("multipart", true);
      if(this.attachments){
        formData.append("files", this.attachments[0]);
      }
      else if(this.attachment) {
        formData.append("files", this.attachment[0]);
      }else{
        console.log("else", this.attachment)
      }
      this.mail_Sending = true;
      let path = URL.HOST_URL.SERVER_URL + support.SENDING_EMAIL();
      axios
        .post(path, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.mail_Sending = false;
          this.$toast.success(`Mail sent successfully`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
          this.toMail = "support@apollodart.com";
          this.cc = "";
          this.subject = "Issue-";
          this.body = "";
          this.capturedImage = null;
          this.attachment = null;
          this.$refs.Upld_Attchmnt.value = "";
          this.fileType = null;
          this.showAttachmentInfo = false;
          console.log(res);
        })
        .catch((err) => {
          this.mail_Sending = false;
          this.$toast.error(err, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        });
    },
    Upload_Attachment(event) {
      this.fileType = null;
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.capturedImage = e.target.result;
          this.fileType = input.files[0].type;
        };
        this.attachment = [input.files[0]];
        reader.readAsDataURL(input.files[0]);
      }
    },
    showCC_Ip_Fun() {
      this.cc = "";
      this.showCC_Ip = !this.showCC_Ip;
    },
    closePoup() {
      this.capturedImage = null;
      this.showCC_Ip = false;
      this.toMail = "support@apollodart.com";
      this.cc = "";
      this.subject = "Issue-";
      this.body = "";
      this.attachment = null;
      this.$refs.Upld_Attchmnt.value = "";
      this.fileType = null;
      this.showAttachmentInfo = false;
      this.toManualEnteredMail="";
      this.closeModelhandler();
    },
    async yes_capture_screen() {
      try {
        this.fileType = null;
        this.capture_screen_confirm_box = false;
      if('ImageCapture' in window){
        const stream = await navigator.mediaDevices.getDisplayMedia({
          preferCurrentTab: true,
        });
        const videoTrack = stream.getVideoTracks()[0];
        const imageCapture = new ImageCapture(videoTrack);
        const bitmap = await imageCapture.grabFrame();
        videoTrack.stop();
        const canvas = document.getElementById("image_to_show");
        const ctx = canvas.getContext("2d");
        canvas.width = bitmap.width;
        canvas.height = bitmap.height;
        ctx.drawImage(bitmap, 0, 0, canvas.width, canvas.height);
        const image = canvas.toDataURL();
        this.capturedImage = image;
        // this turns the base 64 string to a [File] object
        const res = await fetch(image);
        const buff = await res.arrayBuffer();
        // clone so we can rename, and put into array for easy proccessing
        const file = [
          new File([buff], `photo_${new Date()}.png`, {
            type: "image/png",
          }),
        ];
        this.fileType = file[0].type;
        this.attachment = file;
        this.captchureClickhandler();
      }
        else{
          const stream = await navigator.mediaDevices.getDisplayMedia({
            video: { preferCurrentTab: true },
          });

          const videoTrack = stream.getVideoTracks()[0];
          // Create a video element to hold the captured stream
          const video = document.createElement('video');
          video.srcObject = stream;
          video.play();

          video.addEventListener('loadeddata', async () => {
            // Create a canvas element to draw the frame from the video
            videoTrack.stop();
            const canvas = document.getElementById("image_to_show");
            const ctx = canvas.getContext("2d");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // Draw the current video frame on the canvas
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            const image = canvas.toDataURL();
            this.capturedImage = image;

            // Stop the video track to free resources
            // this turns the base 64 string to a [File] object
            const res = await fetch(image);
            const buff = await res.arrayBuffer();
            // clone so we can rename, and put into array for easy proccessing
            const file = [
              new File([buff], `photo_${new Date()}.png`, {
                type: "image/png",
              }),
            ];
            this.fileType = file[0].type;
            this.attachment = file;
            this.captchureClickhandler();
          });
        }
      } catch (err) {
        // this.show_sendMail_modal = true;
        this.captchureClickhandler();
        console.error(err);
      }
    },
  },
  mounted() {
    let apollodart = this.$serviceHelpers.getDetails("apollodart");
    if (
      apollodart.user &&
      apollodart.user.entity &&
      apollodart.user.entity.entity_name
    ) {
      //assigning value to from address
      this.from = apollodart.user?.user_name;
      this.login_user_as_cc = apollodart.user?.user_name;
    }
  },
  expose: ["yes_capture_screen"],
};
</script>
